
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { NotFoundComponent } from './core/shared/components/not-found/not-found.component';
import { HttpClientModule } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';


const routes: Routes = [
  {path: '', loadChildren: () => import ('./public/public.module').then(m => m.PublicModule) },
  {path: '**', component: NotFoundComponent }
];

@NgModule({

  imports: [
    CommonModule,
    FormsModule,
    RouterModule.forRoot(routes),
    HttpClientModule,
    ReactiveFormsModule

  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
